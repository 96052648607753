<template>
    <div>
        <input class="hidden" type="file" accept="image/*" ref="file" @change="change"/>
        <div class="relative inline-block mt-1 w-full h-[300px]">
            <img :src="src" alt="Image Upload" class="h-full w-full object-cover">
            <div class="absolute top-0 h-full w-full bg-opacity-50 bg-blue-200 flex items-center justify-center">
                <button @click.prevent="browse()">
                    <fa-icon :icon="['fas', 'plus']" size="xl" style="color: black" />
                    <p class="text-sm text-black font-bold">Upload</p>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    props: {
        defaultSrc : {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            src: this.defaultSrc,
            file: null
        }
    },

    methods: {
        browse() {
            this.$refs.file.click();
        },

        async change(e){
            this.file = e.target.files[0];

            if(this.file){
                const blob = new Blob([this.file], { type: this.file.type })

                let reader = new FileReader();
                reader.readAsDataURL(blob);

                reader.onload = (e) => {
                    this.src = e.target.result;
                    this.$emit('imageUrl', this.src)
                };
            }
        },

        resetSrc() {
            this.src = require("@/assets/images/defaultImage.jpeg");
        }
    }
}

</script>