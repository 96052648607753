<template>
    <div class="main-container text-white">
        <div class="welcome-container">
            <PublicHeaderBar />
        </div>

        <div class="px-8">
            <div class="pt-28 text-sm flex items-center justify-center">
                <router-link to="/">
                    <p>Home</p>
                </router-link>
                <fa-icon icon="angle-right" style="color: #ffffff;" class="px-3" />
                <p class="font-bold">Become A Model</p>
            </div>

            <div class="becoming-images-container w-full py-8">
                <div class="becoming-images flex justify-around mb-5">
                    <div class="becomingImage1 mr-3"></div>
                    <div class="becomingImage2 ml-3"></div>
                </div>
            </div>

            <div>
                <h1 class="text-center text-[35px] font-bold my-5">PHOTOS & PERSONAL INFO</h1>
                <div class="personal-info w-full items-center justify-between">
                    <div class="instruction w-[60%] pr-4">
                        <p class="font-bold mb-3">For your photos:</p>
                        <ul class="">
                            <li>* No smiles</li>
                            <li>* Relax and be your natural and empowered self</li>
                            <li>* Wear form fitted clothing so that we can clearly see your body shape</li>
                            <li>* Do not wear any makeup or large accessories for instance hoop earrings or bracelets as they may be distracting</li>
                        </ul>
                    </div>
                    <div class="sample-polaroid flex w-[40%]">
                        <div class="leftImage mr-4 w-full"></div>
                        <div class="rightImage w-full"></div>
                    </div>
                </div>
            </div>

            <form @submit.prevent="submitForm">
                <div class="mt-8">
                    <div class="grid grid-cols-2 gap-y-3 gap-x-6 text-sm">
                        <div class="w-full" :class="{ 'error': errors.firstNameError }">
                            <label for="FirstName" class="font-bold">FIRST NAME</label>
                            <input v-model="form.firstName" name="FirstName" />
                        </div>
                        <div :class="{ 'error': errors.lastNameError }">
                            <label for="LastName" class="font-bold">LAST NAME</label>
                            <input v-model="form.lastName" name="LastName" />
                        </div>
                        <div :class="{ 'error': errors.emailAddressError }">
                            <label for="EmailAddress" class="font-bold">EMAIL ADDRESS</label>
                            <input v-model="form.emailAddress" name="EmailAddress" />
                        </div>
                        <div :class="{ 'error': errors.phoneNumberError }">
                            <label for="PhoneNumber" class="font-bold">PHONE NUMBER</label>
                            <input v-model="form.phoneNumber" name="PhoneNumber" />
                        </div>
                        <div :class="{ 'error': errors.dateOfBirthError }">
                            <label for="DateOfBirth" class="font-bold">DATE OF BIRTH</label>
                            <input v-model="form.dateOfBirth" name="DateOfBirth" type="date" />
                        </div>
                        <div :class="{ 'error': errors.cityError }">
                            <label for="City" class="font-bold">CITY</label>
                            <input v-model="form.city" name="City" />
                        </div>
                        <div :class="{ 'error': errors.stateError }">
                            <label for="State" class="font-bold">STATE</label>
                            <input v-model="form.state" name="State" />
                        </div>
                        <div :class="{ 'error': errors.countryError }">
                            <label for="Country" class="font-bold">COUNTRY</label>
                            <input v-model="form.country" name="Country" />
                        </div>
                        <div :class="{ 'error': errors.postalCodeError }">
                            <label for="PostalCode" class="font-bold">POSTAL CODE</label>
                            <input v-model="form.postalCode" name="PostalCode" />
                        </div>
                        <div>
                            <label for="InstagramHandle" class="font-bold">INSTAGRAM</label>
                            <input v-model="form.instagramHandle" name="InstagramHandle" />
                        </div>
                    </div>
                </div>
                <div class="upload grid grid-cols-6 gap-y-2 gap-x-4 py-8">
                    <ApplicationPolaroid :default-src="form.polaroid.polaroid1" ref="polaroid1" @imageUrl="handleUrl('polaroid1', $event)"/>
                    <ApplicationPolaroid :default-src="form.polaroid.polaroid2" ref="polaroid2" @imageUrl="handleUrl('polaroid2', $event)"/>
                    <ApplicationPolaroid :default-src="form.polaroid.polaroid3" ref="polaroid3" @imageUrl="handleUrl('polaroid3', $event)"/>
                    <ApplicationPolaroid :default-src="form.polaroid.polaroid4" ref="polaroid4" @imageUrl="handleUrl('polaroid4', $event)"/>
                    <ApplicationPolaroid :default-src="form.polaroid.polaroid5" ref="polaroid5" @imageUrl="handleUrl('polaroid5', $event)"/>
                    <ApplicationPolaroid :default-src="form.polaroid.polaroid6" ref="polaroid6" @imageUrl="handleUrl('polaroid6', $event)"/>
                </div>


                <div>
                    <h1 class="text-center text-[35px] font-bold my-5">MEASUREMENTS</h1>
                    <div class="personal-info w-full items-center justify-between">
                        <div class="sample-polaroid flex w-[40%] mb-3">
                            <div class="measurement-left mr-4 w-full"></div>
                            <div class="measurement-right w-full"></div>
                        </div>
                        <div class="instruction w-[60%] pl-4">
                            <p>Ensure your measurements are correctly aligned with our requirements (Male height: 6ft and above. Female height: 5'8 and above) for both genders, Use a measuring tape for more accurate results.</p>
                        </div>
                    </div>
                </div>

                <div class="py-10">
                    <div class="grid grid-cols-2 gap-y-3 gap-x-6 text-sm">
                        <div class="w-full" :class="{ 'error': errors.heightError }">
                            <label for="Height" class="font-bold">HEIGHT(CM)</label>
                            <input v-model="form.height" name="Height" />
                        </div>
                        <div :class="{ 'error': errors.weightError }">
                            <label for="Weight" class="font-bold">WEIGHT(KG)</label>
                            <input v-model="form.weight" name="Weight" />
                        </div>
                        <div>
                            <label for="Chest" class="font-bold">CHEST(CM)</label>
                            <input v-model="form.chest" name="Chest" />
                        </div>
                        <div :class="{ 'error': errors.hipError }">
                            <label for="Hip" class="font-bold">HIP(CM)</label>
                            <input v-model="form.hip" name="Hip" />
                        </div>
                        <div :class="{ 'error': errors.shoulderError }">
                            <label for="Shoulder" class="font-bold">SHOULDERS(CM)</label>
                            <input v-model="form.shoulder" name="Shoulder" />
                        </div>
                        <div>
                            <label for="Bust" class="font-bold">BUST(CM)</label>
                            <input v-model="form.bust" name="Bust" />
                        </div>
                        <div :class="{ 'error': errors.waistError }">
                            <label for="waist" class="font-bold">WAIST(CM)</label>
                            <input v-model="form.waist" name="Waist" />
                        </div>
                        <div :class="{ 'error': errors.hairColorError }">
                            <label for="HairColor" class="font-bold">HAIR COLOR</label>
                            <input v-model="form.hairColor" name="HairColor" />
                        </div>
                        <div :class="{ 'error': errors.eyeColorError }">
                            <label for="EyeColor" class="font-bold">EYE COLOR</label>
                            <input v-model="form.eyeColor" name="EyeColor" />
                        </div>
                        <div :class="{ 'error': errors.shoeSizeError }">
                            <label for="ShoeSize" class="font-bold">SHOE SIZE</label>
                            <input v-model="form.shoeSize" name="ShoeSize" type="number" />
                        </div>
                    </div>
                    <div class="policy-agreement flex items-center">
                        <input v-model="form.confirmation" type="checkbox" id="checkboxInput" />
                        <label>
                            <p class="text-xs text-red-500" v-if="errors.confirmationError === true">* Required</p>
                            I confirm that the images and information being submitted are of myself, or that I have permission to submit the images and information on behalf of the applicant.
                        </label>
                    </div>
                    <div class="flex justify-center mt-10 mb-20 ease-out duration-300 hover:text-[#D3D738]">
                        <button :disabled="isSubmitting" class="font-bold">
                            {{ isSubmitting ? 'SENDING...' : 'SEND APPLICATION' }}
                            <fa-icon :icon="['fas', 'arrow-right']" style="color: #fcfcfc;" class="ml-2" />
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <FooterBar />
    </div>
</template>

<script>
import PublicHeaderBar from '@/layouts/PublicHeaderBar.vue';
import ApplicationPolaroid from '@/components/ApplicationPolaroid.vue';
import FooterBar from '@/layouts/FooterBar.vue';
import { useToast } from "vue-toastification";


export default {
    components: {
        PublicHeaderBar, ApplicationPolaroid, FooterBar
    },

    data(){
        return {
            isSubmitting: false,
            form: {
                firstName: "",
                lastName: "",
                emailAddress: "",
                phoneNumber: "",
                dateOfBirth: "",
                city: "",
                state: "",
                country: "",
                postalCode: "",
                instagramHandle: "@",
                height: "",
                weight: "",
                chest: "",
                hip: "",
                shoulder: "",
                bust: "",
                hairColor: "",
                eyeColor: "",
                shoeSize: "",
                waist: "",
                confirmation: false,
                polaroid: {
                    polaroid1: require("@/assets/images/defaultImage.jpeg"),
                    polaroid2: require("@/assets/images/defaultImage.jpeg"),
                    polaroid3: require("@/assets/images/defaultImage.jpeg"),
                    polaroid4: require("@/assets/images/defaultImage.jpeg"),
                    polaroid5: require("@/assets/images/defaultImage.jpeg"),
                    polaroid6: require("@/assets/images/defaultImage.jpeg"),
                },
                selectedPolaroids: []
            },

            errors: {
                firstNameError: "",
                lastNameError: "",
                emailAddressError: "",
                phoneNumberError: "",
                dateOfBirthError: "",
                cityError: "",
                stateError: "",
                countryError: "",
                postalCodeError: "",
                heightError: "",
                weightError: "",
                shoulderError: "",
                hairColorError: "",
                eyeColorError: "",
                shoeSizeError: "",
                waistError: "",
                hipError: "",
                confirmationError: false
            }
        }
    },

    methods: {
        async handleUrl(identifier, url){
            this.form.polaroid[identifier] = url;
        },

        async uploadPolaroids() {
            let uploadUrl = process.env.VUE_APP_ENVIRONMENT === 'production'
                    ? process.env.VUE_APP_PRODUCTION_CLOUDINARY_UPLOAD_URL
                    : process.env.VUE_APP_DEVELOPMENT_CLOUDINARY_UPLOAD_URL;

            for(let polaroid of Object.values(this.form.polaroid)){
                if(polaroid !== process.env.VUE_APP_DEFAULT_IMAGE_URL){
                    const formData = new FormData();
                    formData.append('file', polaroid);
                    formData.append('upload_preset', 'jabari');
    
                    try {
                        const response = await fetch(uploadUrl, {
                            method: 'POST',
                            body: formData,
                        });
    
                        if (response.status === 200) {
                            response.json().then(res => {
                                this.form.selectedPolaroids.push(res.secure_url)
                            });

                        } else {
                            console.error('Error uploading file to Cloudinary:', response.statusText);
                        }
                    } catch (error) {
                        console.error('Error uploading file to Cloudinary:', error);
                    }
                }
            }
        },

        async submitForm() {
            const toast = useToast();

            this.validateModelDetails();

            try {
                if(this.isAllModelDetailsValidated){
                    this.isSubmitting = true;

                    await this.uploadPolaroids();

                    const formData = new FormData(this.$el.querySelector('form'));
                    formData.append("PolaroidLinks", this.form.selectedPolaroids.join(", - "))
    
                    const response = await fetch(process.env.VUE_APP_GOOGLE_SCRIPT_URL, {
                        method: "POST",
                        body: formData,
                    });
    
                    if (response.ok) {
                        toast.success("Form submission was successful. We will get back to you within 72 hours.", {
                            timeout: 5000
                        });

                    } else {
                        toast.error("Error submitting the form. Please try again.", {
                            timeout: 5000
                        });
                    }

                    this.resetForm();
                }
            } catch (error) {
                toast.error("An unexpected error occurred. Please try again later.", {
                    timeout: 5000
                });
            } finally {
                this.isSubmitting = false;
            }
        },

        resetForm(){
            this.isSubmitting = false
            this.form = {
                firstName: "",
                lastName: "",
                emailAddress: "",
                phoneNumber: "",
                dateOfBirth: "",
                city: "",
                state: "",
                country: "",
                postalCode: "",
                instagramHandle: "@",
                height: "",
                weight: "",
                chest: "",
                hip: "",
                shoulder: "",
                bust: "",
                hairColor: "",
                eyeColor: "",
                shoeSize: "",
                waist: "",
                confirmation: false,
                polaroid: {
                    polaroid1: require("@/assets/images/defaultImage.jpeg"),
                    polaroid2: require("@/assets/images/defaultImage.jpeg"),
                    polaroid3: require("@/assets/images/defaultImage.jpeg"),
                    polaroid4: require("@/assets/images/defaultImage.jpeg"),
                    polaroid5: require("@/assets/images/defaultImage.jpeg"),
                    polaroid6: require("@/assets/images/defaultImage.jpeg"),
                },
                selectedPolaroids: []
            },

            
            this.$refs.polaroid1.resetSrc();
            this.$refs.polaroid2.resetSrc();
            this.$refs.polaroid3.resetSrc();
            this.$refs.polaroid4.resetSrc();
            this.$refs.polaroid5.resetSrc();
            this.$refs.polaroid6.resetSrc();
        },

        validateModelDetails(){
            this.errors.firstNameError = this.form.firstName === "";
            this.errors.lastNameError = this.form.lastName === "";
            this.errors.emailAddressError = this.form.emailAddress === "";
            this.errors.phoneNumberError = this.form.phoneNumber === "";
            this.errors.dateOfBirthError = this.form.dateOfBirth === "";
            this.errors.cityError = this.form.city === "";
            this.errors.stateError = this.form.state === "";
            this.errors.countryError = this.form.country === "";
            this.errors.postalCodeError = this.form.postalCode === "";
            this.errors.heightError = this.form.height === "";
            this.errors.weightError = this.form.weight === "";
            this.errors.shoulderError = this.form.shoulder === "";
            this.errors.hairColorError = this.form.hairColor === "";
            this.errors.eyeColorError = this.form.eyeColor === "";
            this.errors.shoeSizeError = this.form.shoeSize === "";
            this.errors.waistError = this.form.waist === "";
            this.errors.hipError = this.form.hip === "";
            this.errors.confirmationError = this.form.confirmation === false;
        }
    },

    computed: {
        isAllModelDetailsValidated(){
            return !this.errors.firstNameError && !this.errors.lastNameError && !this.errors.emailAddressError && !this.errors.phoneNumberError
            && !this.errors.dateOfBirthError && !this.errors.cityError && !this.errors.stateError && !this.errors.countryError 
            && !this.errors.postalCodeError && !this.errors.heightError && !this.errors.weightError && !this.errors.shoulderError 
            && !this.errors.hairColorError && !this.errors.eyeColorError && !this.errors.shoeSizeError && !this.errors.waistError 
            && !this.errors.hipError && !this.errors.confirmationError;
        },

    }
}
</script>

<style scoped>
.main-container {
    background-color: #1B1C1E;
    min-height: 100vh;
}
.becoming-images {
    height: 70vh;
}
.becomingImage1 {
    background-image: url('https://res.cloudinary.com/dfzi3zzyp/image/upload/v1708900360/Jabari/platform/hvahymlksjyitnlnhtt9.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 40%;
}
.becomingImage2 {
    background-image: url('https://res.cloudinary.com/dfzi3zzyp/image/upload/v1708900360/Jabari/platform/o3orfiyqy6qtx7ybvjgc.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 60%;
}
.leftImage {
    background-image: url('@/assets/images/personalInfo1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 30vh;
}
.rightImage {
    background-image: url('@/assets/images/personalInfo2.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 30vh;
}
.measurement-left {
    background-image: url('@/assets/images/measurement1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 30vh;
}
.measurement-right {
    background-image: url('@/assets/images/measurement2.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 30vh;
}
input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
}
input:focus, input:active {
    outline: none;
    outline-width: 0;
    border-bottom: 1px solid #D3D738;
}
.personal-info {
    display: flex;
}
.policy-agreement {
    display: flex;
    align-items: center;
    margin: 30px 0 10px 0;
}
#checkboxInput {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 10px;
}

p, li {
    font-family: 'Manrope Variable', sans-serif;
}

h1, label, button {
    font-family: 'PT Serif', serif;
}
.policy-agreement label {
    font-family: 'Manrope Variable', sans-serif;
}
.error input {
  border-color: red;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 1025px){
    .becoming-images {
        height: 40vh;
    }

    .leftImage, .rightImage, .measurement-left, .measurement-right {
        height: 13vh;
    }
}

@media (max-width: 765px){
    .becoming-images {
        height: 20vh;
    }

    .becoming-images-container p {
        font-size: 12px;
    }

    .personal-info {
        display: block;
    }

    .instruction {
        padding-bottom: 20px;
        padding-left: 0px;
    }

    .instruction, .sample-polaroid {
        width: 100%;
    }

    .leftImage, .rightImage, .measurement-left, .measurement-right {
        height: 17vh;
    }

    .upload {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    p {
        font-size: 17px;
    }

    li {
        font-size: 15px;
    }
}
</style>