<template>
    <div>
        <div class="header" :class="{ 'scrolled': scrolled }">
            <div class="menu flex text-sm">
                <router-link :to="{ name: 'model', params: { category: 'women' }}">
                    <p class="mr-3 cursor-pointer">WOMEN</p>
                </router-link>
                <router-link :to="{ name: 'model', params: { category: 'men' }}">
                    <p class="mr-3 cursor-pointer">MEN</p>
                </router-link>
                <router-link :to="{ name: 'about' }">
                    <p class="mr-3 cursor-pointer">ABOUT US</p>
                </router-link>
                <router-link :to="{ name: 'contact' }">
                    <p class="mr-2 cursor-pointer">CONTACT</p>
                </router-link>
            </div>
            <router-link to="/">
                <div class="logo my-4 text-center font-bold cursor-pointer">
                    <img class="h-14 w-30" src="@/assets/logo.png" alt="Jabari Logo" />
                </div>
            </router-link>
            <div class="menu flex items-center text-sm">
                <router-link :to="{ name: 'application' }">
                    <p class="mr-6 cursor-pointer">BECOME A MODEL</p>
                </router-link>
                <div class="social-media-icons flex items-center">
                    <a href="https://www.instagram.com/jabarimodels/" target="_blank" rel="noopener noreferrer">
                        <fa-icon class="mr-3 cursor-pointer"  :icon="['fab', 'instagram']" style="color: #fafafa;" />
                    </a>
                    <a href="https://www.facebook.com/jabarimodels" target="_blank" rel="noopener noreferrer">
                        <fa-icon class="mr-3 cursor-pointer" :icon="['fab', 'facebook']" style="color: #fafafa;" />
                    </a>
                    <a href="https://x.com/jabarimodels" target="_blank" rel="noopener noreferrer">
                        <fa-icon class="mr-3 cursor-pointer" :icon="['fab', 'twitter']" style="color: #fafafa;" />
                    </a>
                    <a href="https://www.tiktok.com/@jabarimodels" target="_blank" rel="noopener noreferrer">  
                        <fa-icon class="cursor-pointer" :icon="['fab', 'tiktok']" style="color: #ffffff;" />
                    </a>
                </div>
            </div>
        </div>

        <div class="header-res text-white" :class="{ 'scrolled': scrolled}">
            <router-link to="/">
                <div class="logo my-4 text-center font-bold cursor-pointer">
                    <img class="h-9 w-20" src="@/assets/logo.png" alt="Jabari Logo" />
                </div>
            </router-link>
            <div>
                <Menu>
                    <MenuButton>
                        <button class="menu-icon">
                            <fa-icon :icon="['fas', 'bars']" style="color: white;" size="xl" />
                        </button>
                    </MenuButton>
                    <transition
                        enter-active-class="transition duration-150 ease-out"
                        enter-from-class="transform scale-95 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-out"
                        leave-from-class="transform scale-100 opacity-100"
                        leave-to-class="transform scale-95 opacity-0" >
                        <MenuItems class="absolute right-3 mt-2 py-4 w-56 origin-top-right divide-y divide-gray-100 z-50 bg-[#1B1C1E] shadow-lg ring-1 ring-black/5 focus:outline-none">
                            <router-link :to="{ name: 'model', params: { category: 'men' } }">
                                <MenuItem v-slot="{ active }">
                                    <button
                                        :class="[
                                            active ? 'text-gray-500' : 'text-white',
                                            'group flex w-full items-center justify-center rounded-md px-2 py-3 text-sm',
                                        ]"
                                        
                                        >
                                        MEN
                                    </button>
                                </MenuItem>
                            </router-link>
                            <router-link :to="{ name: 'model', params: { category: 'women' } }">
                                <MenuItem v-slot="{ active }">
                                    <button
                                        :class="[
                                            active ? 'text-gray-500' : 'text-white',
                                            'group flex w-full items-center justify-center rounded-md px-2 py-3 text-sm',
                                        ]"
                                        
                                        >
                                        WOMEN
                                    </button>
                                </MenuItem>
                            </router-link>
                            <router-link :to="{ name: 'contact' }">
                                <MenuItem v-slot="{ active }">
                                    <button
                                        :class="[
                                            active ? 'text-gray-500' : 'text-white',
                                            'group flex w-full items-center justify-center rounded-md px-2 py-3 text-sm',
                                        ]"
                                        
                                        >
                                        CONTACT
                                    </button>
                                </MenuItem>
                            </router-link>
                            <router-link :to="{ name: 'about' }">
                                <MenuItem v-slot="{ active }">
                                    <button
                                        :class="[
                                            active ? 'text-gray-500' : 'text-white',
                                            'group flex w-full items-center justify-center rounded-md px-2 py-3 text-sm',
                                        ]"
                                        
                                        >
                                        ABOUT US
                                    </button>
                                </MenuItem>
                            </router-link>
                            <router-link :to="{ name: 'application' }">
                                <MenuItem v-slot="{ active }">
                                    <button
                                        :class="[
                                            active ? 'text-gray-500' : 'text-white',
                                            'group flex w-full items-center justify-center rounded-md px-2 py-3 text-sm',
                                        ]"
                                        
                                        >
                                        BECOME A MODEL
                                    </button>
                                </MenuItem>
                            </router-link>
                            <MenuItem v-slot="{ active }">
                                <button
                                    :class="[
                                        active ? 'text-gray-500' : 'text-white',
                                        'group flex w-full items-center justify-center px-2 pt-3 mt-3 text-sm',
                                    ]"
                                    
                                    >
                                    <div class="social-media-icons flex items-center">
                                        <a href="https://www.instagram.com/jabarimodels/" target="_blank" rel="noopener noreferrer">
                                            <fa-icon class="mr-3 cursor-pointer"  :icon="['fab', 'instagram']" style="color: #fafafa;" />
                                        </a>
                                        <a href="https://www.facebook.com/jabarimodels" target="_blank" rel="noopener noreferrer">
                                            <fa-icon class="mr-3 cursor-pointer" :icon="['fab', 'facebook']" style="color: #fafafa;" />
                                        </a>
                                        <a href="https://x.com/jabarimodels" target="_blank" rel="noopener noreferrer">
                                            <fa-icon class="mr-3 cursor-pointer" :icon="['fab', 'twitter']" style="color: #fafafa;" />
                                        </a>
                                        <a href="https://www.tiktok.com/@jabarimodels" target="_blank" rel="noopener noreferrer">  
                                            <fa-icon class="cursor-pointer" :icon="['fab', 'tiktok']" style="color: #ffffff;" />
                                        </a>
                                    </div>
                                </button>
                            </MenuItem>
                        </MenuItems>
                    </transition>
                </Menu>
            </div>
        </div>
    </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

export default {
    components: {
        Menu, MenuButton, MenuItems, MenuItem
    },

    data() {
        return {
            scrolled: false,
        };
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },

    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll() {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            this.scrolled = scrollPosition > 100;
        },
    },
}
</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 8px 20px;
    position: fixed;
    width: 100%; 
    top: 0; 
    transition: background-color 0.5s ease;
}

.header, .header-res {
    z-index: 1000;
}

.menu p {
  font-family: 'Manrope Variable', sans-serif;
}

button {
    font-family: 'Manrope Variable', sans-serif;
}

.header-res {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 8px 20px;
    position: fixed;
    width: 100%;
    transition: background-color 0.5s ease;
}

.scrolled {
  background-color: black;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px; 
}

.router-link-exact-active {
    color: #D3D738;
}

.header p {
    transition: 0.2s ease-in-out;
}

.header p:hover {
    color: #D3D738;
}

@media (max-width: 1025px){
    .header {
        visibility: hidden;
    }
}

@media (min-width: 1026px){
    .header-res {
        visibility: hidden;
    }
}
</style>