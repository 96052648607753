import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import "./assets/tailwind.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import '@fontsource-variable/manrope';
import '@fontsource/pt-serif';


const pinia = createPinia();
const app = createApp(App);
const options = {
    position: POSITION.BOTTOM_RIGHT
};

library.add(far, fas, fab)
app.use(router)
app.use(pinia)
app.use(Toast, options);
app.component('fa-icon', FontAwesomeIcon)
app.mount('#app')
