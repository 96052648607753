<template>
    <div class="footer px-8 flex items-center justify-between">
        <div class="policy text-sm cursor-pointer">
            <p>JABARI MODELS 2024</p>
            <p class="mx-5">ALL RIGHTS RESERVED</p>
        </div>
        <div class="logo my-4 text-center text-white">
            <img class="h-5 w-12" src="@/assets/logo.png" alt="Jabari Logo" />
        </div>
        <div class="policy text-sm">
            <p class="mx-5">TERMS OF USE</p>
            <p>PRIVACY POLICY</p>
        </div>
    </div>
</template>

<style>
.footer {
    width: 100%;
    background-color: #212121;
}

.footer p {
    font-family: 'Manrope Variable', sans-serif;
}

.policy {
    display: flex;
}

.policy p {
    cursor: pointer;
}

@media (max-width: 865px){
    .policy {
        display: block;
    }

    .policy p{
        font-size: 10px;
        margin-left: 0;
        margin-right: 0;
    }
}
</style>