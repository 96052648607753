import { createRouter, createWebHistory } from "vue-router"
import Application from '@/views/Public/BecomeAModel.vue'
import { auth } from "@/firebase"
import { onAuthStateChanged } from "firebase/auth"

const routes = [
    {
        path: '/:pathMatch(.*)*',
        redirect: '/',
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import('@/views/Admin/LoginPage.vue')
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import('@/views/Admin/HomePage.vue'),
        redirect: { name: "modelCategory", params: { category: "male" } },
        children: [
            {
                path: ":category",
                name: "modelCategory",
                component: () => import('@/views/Admin/ModelPage.vue'),
                props: route => ({
                    category: route.params.category,
                }),
                meta: { requiresAuth: true }
            }
        ]
    },
    {
        path: "/",
        name: "landing",
        component: () => import('@/views/Public/LandingPage.vue')
    },
    {
        path: "/about",
        name: "about",
        component: () => import('@/views/Public/AboutUs.vue')
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import('@/views/Public/ContactPage.vue')
    },
    {
        path: "/application",
        name: "application",
        component: Application
    },
    {
        path: "/models",
        name: "models",
        component: () => import('@/views/Public/ModelFactory.vue'),
        children: [
            {
                path: ":category",
                name: "model",
                component: () => import('@/views/Public/AllModels.vue'),
                props: route => ({
                    category: route.params.category
                })
            },
            {
                path: ":category/:modelId",
                name: "model-profile",
                component: () => import('@/views/Public/ModelProfile.vue'),
                props: true
            }
        ]
    },
    {
        path: "/edit/:category/:modelId",
        name: "edit-profile",
        component: () => import('@/views/Admin/EditModel.vue'),
        props: true,
        meta: { requiresAuth: true }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

function isLoggedIn(){
    return new Promise((resolve) => {
        let currentUser = localStorage.getItem("user")
        onAuthStateChanged(auth, (user) => {
          if (user && user.email === currentUser) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
}

router.beforeEach(async (to) => {
    const authenticated = await isLoggedIn();

    if(to.meta.requiresAuth && !authenticated){
        return {
            name: 'admin',
            query: { redirect: to.fullPath },
        };
    }
})

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;